import { useState, useEffect, useRef } from "react";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import { Fragment } from "react/cjs/react.production.min";
// import URLS from "../../constants";
import ButtonIconBorder from "../Common/ButtonIconBorder";
import DialogResource from "./DialogResource";
// import api from "../../api";

const Resource = () => {
  // const [caseStudies, setCaseStudies] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  // const getCaseStudies = () => {
  //   api.caseStudy
  //       .getCaseStudy()
  //       .then((result) => {
  //           // console.log("get case study ", result);
  //           if (result.status == '1') {
  //               console.log("get case study data", result.data);
  //               setCaseStudies(result.data);
  //           }
  //       })
  //       .catch((error) => {
  //           console.log(error);
  //       });

  //   let URL = URLS.getCaseStudies;
  //   fetch(URL)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setCaseStudies(data);
  //     });
  // };

  // useEffect(() => {
  //   getCaseStudies();
  // }, []);

  // const capitalizeFirstLetter = (string) => {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  const resourcesTab = [
    {
      title: "Case Studies",
      link: "#Case Studies",
      active: true,
      cards: [
        {
          title: "Predictive Analytics in Financial Services",
          image: "assets/images/case-img1.png",
          link: "/caseStudy1",
          name: "Read More",
        },
        {
          title: "Our Work on Recommender System",
          image: "assets/images/case-img2.png",
          link: "/caseStudy2",
          name: "Read More",
        },
        {
          title: "Our Work in Legal Analytics",
          image: "assets/images/case-img3.png",
          link: "/caseStudy3",
          name: "Read More",
        },
        {
          title: "Automated Quality Control to Detect Defects in Painted Parts",
          image: "assets/images/case-img4.png",
          link: "/caseStudy4",
          name: "Read More",
        },
        {
          title: "Our AI solutions for E-Commerce",
          image: "assets/images/case-img5.png",
          link: "/caseStudy5",
          name: "Read More",
        },
        {
          title: "Cooking up ML Recipes for a No/Low Code AI Platform",
          image: "assets/images/case-img6.png",
          link: "/caseStudy6",
          name: "Read More",
        },
        {
          title: "Building an Automated Content Recommendation System",
          image: "assets/images/case-img7.png",
          link: "/caseStudy7",
          name: "Read More",
        },
        {
          title:"Revolutionizing Industries with Digital Twin Technology",
          image:"assets/images/case-img8.png",
          link:"/caseStudy8",
          name:"Read More",
        }
      ],
    },
    {
      title: "Videos",
      link: "#Videos",
      active: false,
      cards: [
        {
          title:"AlgoAnalytics: An Overview",
          videoimage:"assets/images/algoanalytics-overview-img.jpg",
          link:"https://www.youtube.com/embed/ob4zQAcoTK0",
        },
        {
          title: "Onestop.ai: An Overview",
          videoimage: "assets/images/onestop-video-img.jpg",
          link: "https://www.youtube.com/embed/QYBKGOubwH4",
          // name:"View more"
        },
        {
          title:"AlgoAnalytics: Our Services in Generative AI",
          videoimage:"assets/images/gen-ai-video-img.jpg",
          link:"https://www.youtube.com/embed/PnSIKurY9cs",
        },
        {
          title:"Aksha: Video Surveillance Solution",
          videoimage:"assets/images/aksha-insight-img.jpg",
          link:"https://www.youtube.com/embed/mcjFTgVKID0",
        },
        {
          title: "aQuality: AI powered Quality Check Tool",
          videoimage: "assets/images/aQuality-video-img.jpg",
          link: "https://www.youtube.com/embed/NFGINIBvK5w",
          // name:"View more"
        },
        // {
        //   title: "Aksha: AI powered Video Surveillance",
        //   videoimage: "assets/images/aksha-video-img.jpg",
        //   link: "https://www.youtube.com/embed/gL7JSDV-wPY",
        //   // name:"View more"
        // },
        {
          title: "AlgoFabric: AI-powered Stock Research Assistant",
          videoimage: "assets/images/algofabric-video-img.jpg",
          link: "https://www.youtube.com/embed/3VZ7JHZ-fWE",
          // name:"View more"
        },
        {
          title:"AlgoAnalytics: Our Offerings for Manufacturing Sector",
          videoimage:"assets/images/manufacturing-video.jpg",
          link:"https://www.youtube.com/embed/H7iFt0R2g6Y",
        }
      ],
    },
    {
      title: "News Releases",
      subtitle:
        "AlgoAnalytics in the news for Capital Alliance with NTT DATA INTELLINK CORPORATION",
      link: "#News Releases",
      active: false,
      cards: [
        {
          title:
            "Algoanalytics announces capital and business alliance with NTT DATA INTELLILINK CORPORATION of Japan",
          image: "assets/images/news-img1.png",
          link: "https://www.einpresswire.com/article/635298028/algoanalytics-announces-capital-and-business-alliance-with-ntt-data-intellilink-corporation-of-japan",
          name: "Read More",
          target: "_blank",
        },
        {
          title:
            "AlgoAnalytics announces partnership with NTT DATA INTELLILINK CORPORATION of Japan",
          image: "assets/images/news-img1.png",
          link: "https://www.nikkei.com/article/DGXZRSP655169_X10C23A5000000/",
          name: "Read More",
          target: "_blank",
        },
        {
          title:
            "Startup Saturday: The Maths Major That Changed The Game For AI",
          image: "assets/images/news-img2.png",
          link: "https://blog.algoanalytics.com/2022/02/16/startup-saturday-the-maths-major-that-changed-the-game-for-ai/",
          name: "Read More",
          target: "_blank",
        },
        {
          title:
            "TextSense Platform by AlgoAnalytics poised to be a game changer in Enterprise Data",
          image: "assets/images/news-img3.png",
          link: "https://blog.algoanalytics.com/2022/02/16/textsense-platform-by-algoanalytics-poised-to-be-a-game-changer-in-enterprise-data/",
          name: "Read More",
          target: "_blank",
        },
        {
          title:
            "Amit Paranjape, Co-Chairperson, IT & ITES Committee, MCCIA in conversation with Aniruddha Pant",
          image: "assets/images/news-img4.png",
          link: "https://blog.algoanalytics.com/2022/03/03/amit-paranjape-co-chairperson-it-ites-committee-mccia-in-conversation-with-aniruddha-pant/",
          name: "Read More",
          target: "_blank",
        },
      ],
    },
    {
      title: "Whitepapers",
      link: "#Whitepapers",
      active: false,
      cards: [
        {
          title: "Introduction to Neural Networks for Image Ananlytics",
          image: "assets/images/whitepaper-img1.png",
          link: "https://algo-case-studies.s3.ap-south-1.amazonaws.com/White+paper+1+-+Introduction+to+Image+analytics.pdf",
          name: "Read More",
          target: "_blank",
        },
        {
          title:
            "How Image Analytics can be used for Obstacle Detection on Road using Deep Learning ?",
          image: "assets/images/whitepaper-img2.png",
          link: " https://algo-case-studies.s3.ap-south-1.amazonaws.com/Whitepaper+2+-+Obstacle+detection+using+Deep+Learning.pdf",
          name: "Read More",
          target: "_blank",
        },
        {
          title: "Detecting small objects from Images / Videos using AI",
          image: "assets/images/whitepaper-img3.png",
          link: " https://algo-case-studies.s3.ap-south-1.amazonaws.com/White+Paper+3+-Small+object+detection+using+image+processing.pdf",
          name: "Read More",
          target: "_blank",
        },
      ],
    },

    {
      title: "Blogs",
      link: "#Blogs",
      active: false,
      cards: [
        {
          title: "Amazon Kendra: Mastering Semantic Search",
          image: "assets/images/blog-img1.png",
          link: "https://blog.algoanalytics.com/2023/12/13/amazon-kendra-mastering-semantic-search/",
          name: "Read More",
        },
        {
          title: "Static Code Analysis using SonarQube",
          image: "assets/images/blog-img2.png",
          link: "https://blog.algoanalytics.com/2023/12/05/static-code-analysis-using-sonarqube/",
          name: "Read More",
        },
        {
          title:
            "CI/CD with Github Actions: Building and Pushing Docker Images to Amazon ECR",
          image: "assets/images/blog-img3.png",
          link: "https://blog.algoanalytics.com/2023/11/20/ci-cd-with-github-actions-building-and-pushing-docker-images-to-amazon-ecr/",
          name: "Read More",
        },
        {
          title:
            "Modernizing Application Deployment and Scaling with Terraform",
          image: "assets/images/blog-img4.png",
          link: "https://blog.algoanalytics.com/2023/11/13/modernizing-application-deployment-and-scaling-with-terraform/",
          name: "Read More",
        },
        {
          title:
            "Navigating Fairness in Recommendations: Unpacking the World of Recommended Large Language Models",
          image: "assets/images/blog-img5.png",
          link: "https://blog.algoanalytics.com/2023/11/03/navigating-fairness-in-recommendations-unpacking-the-world-of-recommended-large-language-models/",
          name: "Read More",
        },
        {
          // title: "See All Posts",
          link: "https://blog.algoanalytics.com/",
          name: "Read More Posts",
        },
      ],
    },
  ];

  const videoDialogArrRef = useRef([]);

  const [activeSection, setActiveSection] = useState(null);
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      const visibleSection = entries.find(
        (entry) => entry.isIntersecting
      )?.target;

      if (visibleSection) {
        setActiveSection(visibleSection.id);
      }
    });

    const sections = document.querySelectorAll("[data-section]");

    sections.forEach((section) => {
      observer.current.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.current.unobserve(section);
      });
    };
  }, []);

  return (
    <Fragment>
      {/* <div className='loader'>
        <div className='loader-inner'>
          <img
            src='assets/images/loader.gif'
            alt='Loader GIF'
            className='loader-img'
            loop='infinite'
          />
        </div>
      </div> */}
      <Navigation />
      <div className='serv-page-hero geek-bg section-bottom' id='resources'>
        <div className='container h-100'></div>
      </div>

      {/* <!-- Hero Section End  --> */}
      <div className='resource-page'>
        <div className='resource-page-container'>
          <div className='common-page-hero-title'>Resources</div>
          <div className='common-page-hero-subtitle'>
            Learn how we have helped our clients to achieve their goals
          </div>
          <div className='resource-container'>
            {/* left pane tab list of resources */}
            <div className='resource-tab-list'>
              {resourcesTab.map((data,index) => {
                return (
                  <a href={data.link} key={index}>
                    <div className='resource-tab'>
                      {/* add activeSection here */}
                      <div
                        className={`resource-button ${
                          activeSection === data.title ? "active" : ""
                        }`}>
                        {data.title}
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>

            {/* right pane cards for each resource */}

            <div className='resource-tab-cards'>
              {resourcesTab.map((data, ri) => {
                return (
                  <div data-section id={data.title} key={ri}>
                    <div className='resource-tab-title'>{data.title}</div>
                    <div className='resource-tab-subtitle'>{data.subtitle}</div>
                    <div className='resource-tab-cards-container'>
                      {data.cards &&
                        data.cards.map((item, index) => {
                          videoDialogArrRef.current[ri] =
                            videoDialogArrRef.current[ri] || [];
                          return (
                            <div
                              className={
                                item.title
                                  ? "resource-tab-card"
                                  : "resource-tab-button"
                              } key={index}>
                              <DialogResource
                                dialogRef={(el) => {
                                  videoDialogArrRef.current[ri][index] = el;
                                }}
                                dialogRefCurr={
                                  videoDialogArrRef.current[ri][index]
                                }
                                // title={`${ri},${index}`}
                                videoSrc={item.link}
                              />
                              <div className='resource-tab-card-image'>
                                {item.videoimage ? (
                                  <div className='video-img-container'>
                                    <img
                                      style={{
                                        maxWidth: 300,
                                        height: 200,
                                      }}
                                      src={item.videoimage}
                                      className='video-img'
                                      alt='video-img'
                                    />
                                    {/* add overlay play button on image center */}
                                    <svg
                                      onClick={() => {
                                        console.log(
                                          "Open Modal ",
                                          ri,
                                          index,
                                          videoDialogArrRef.current[ri][index]
                                        );
                                        videoDialogArrRef.current[ri][
                                          index
                                        ]?.showModal();
                                      }}
                                      xmlns='http://www.w3.org/2000/svg'
                                      fill='white'
                                      width='60'
                                      height='60'
                                      viewBox='0 0 512 512'>
                                      <path d='M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.3 .5s-12.3-12.2-12.3-20.9V168c0-8.7 4.7-16.7 12.3-20.9z' />
                                    </svg>
                                  </div>
                                ) : item.image ? (
                                  <img
                                    style={{
                                      maxWidth: 250,
                                      height: 200,
                                    }}
                                    src={
                                      item.image != null
                                        ? item.image
                                        : "assets/images/image_not_available.png"
                                    }
                                    className='resource-img'
                                    alt='resource-img'
                                  />
                                ) : null}
                              </div>
                              <div className='resource-tab-card-title'>
                                {item.title}
                              </div>
                              {item.name ? (
                                <a
                                  href={item.link}
                                  target={item.target}
                                  rel='noreferrer'>
                                  <ButtonIconBorder
                                    name={item.name}
                                    svgPathAttributes={{
                                      fill: "currentColor",
                                      d: "M4 11.25a.75.75 0 0 0 0 1.5h9.25V18a.75.75 0 0 0 1.28.53l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.28.53v5.25H4Z",
                                    }}
                                    styleClasses='justify-start'
                                  />
                                </a>
                              ) : null}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="partner-section partner-bg-none" >
                <div className="container ">
                    <div className="partner-top row justify-content-between flex-wrap align-items-center">
                        <div className="partner-left1">
                            <h3 className="small-heading text-left">Academic Partnerships</h3>
                        </div>
                        <div className="partner-left2">
                            <div className="logo-container row justify-content-around">
                                <a className="">
                                    <img src="assets/images/logo/l11.png" className="img-fluid" alt="image" />
                                </a>
                                <a className="">
                                    <img src="assets/images/logo/l22.png" className="img-fluid" alt="image" />
                                </a>
                                <a className="">
                                    <img src="assets/images/logo/l33.png" className="img-fluid" alt="image" />
                                </a>
                                <a className="">
                                    <img src="assets/images/logo/l44.png" className="img-fluid" alt="image" />
                                </a>
                            </div>
                            <div className="assets/logo-container row justify-content-around my-5">
                                <a className="">
                                    <img src="assets/images/logo/l55.png" className="img-fluid" alt="image" />
                                </a>
                                <a className="">
                                    <img src="assets/images/logo/l66.png" className="img-fluid" alt="image" />
                                </a>
                                <a className="">
                                    <img src="assets/images/logo/l77.png" className="img-fluid" alt="image" />
                                </a>
                            </div>

                            <div className="logo-container row justify-content-around">
                                <a className="">
                                    <img src="assets/images/logo/l88.png" className="img-fluid" alt="image" />
                                </a>
                                <a className="">
                                    <img src="assets/images/logo/l99.png" className="img-fluid" alt="image" />
                                </a>
                                <a className="">
                                    <img src="assets/images/logo/l10.png" className="img-fluid" alt="image" />
                                </a>
                                <a className="">
                                    <img src="assets/images/logo/l12.png" className="img-fluid" alt="image" />
                                </a>
                            </div>
                            <div id="serv-3"></div>

                        </div>
                    </div>
                </div>

            </div> */}
      <Footer />
    </Fragment>
  );
};
export default Resource;
