import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import { Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ButtonIconBorder from "../Common/ButtonIconBorder";
import $ from "jquery";
import ContactUs from "../Common/ContactUs";

const Products = (props) => {
  useEffect(() => {
    $(".loader").slideUp("slow");
    $("body").addClass("animate");
  }, []);
  const productsData = [
    {
      title: "Aksha",
      point: {
        point1: "AI-powered intelligent video surveillance solution",
        point2: "Transforms basic CCTV setups into smart surveillance systems",
        point3: "Utilizes a self-learning computer vision algorithm",
        point4:
          "Capable of detecting objects and identifying unusual situations",
        point5: "Generates real-time alerts based on identified events",
        point6:
          "Allows users to create custom alerts according to their specific needs",
        point7: "Enables 24x7 smart surveillance of their premises",
      },
      video: "https://www.youtube.com/embed/mcjFTgVKID0",
      logo: "assets/images/Aksha.jpg",
      imageOnLeft: true,
      button: {
        name: "Learn More",
        link: "https://aksha.algoanalytics.com/",
      },
    },
    {
      title: "aQuality",
      point: {
        point1: "AI-powered visual inspection system",
        point2: "Specialized in real-time surface defects detection",
        point3: "Provides statistical data on the inspection process",
        point4: "Generates comprehensive reports for a complete overview",
      },
      video: "https://www.youtube.com/embed/NFGINIBvK5w",
      logo: "assets/images/aQuality_logo.png",
      imageOnLeft: false,
      button: {
        name: "Learn More",
        link: "https://aquality.algoanalytics.com/",
      },
    },
    {
      title: "AlgoFabric",
      point: {
        point1: "Offers a comprehensive 360° view of your stocks",
        point2:
          "Addresses the challenge of tracking abundant financial news online",
        point3: "Tailored for stock investors",
        point4:
          "Focuses on delivering information about users' favorite stocks",
      },
      video: "https://www.youtube.com/embed/3VZ7JHZ-fWE",
      logo: "assets/images/AlgoFabric_logo.png",
      imageOnLeft: true,
      button: {
        name: "Learn More",
        link: "https://algofabric.algoanalytics.com/",
      },
    },
  ];

  const videoRefs = useRef([]);
  videoRefs.current = [];

  const addToRefs = (el) => {
    if (el && !videoRefs.current.includes(el)) {
      videoRefs.current.push(el);
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        videoRefs.current.forEach((iframe) => {
          iframe.src = iframe.src.replace("autoplay=1", "autoplay=0");
        });
      }
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          entry.target.src = entry.target.src.replace(
            "autoplay=1",
            "autoplay=0"
          );
        } 
        // else {
        //   entry.target.src = entry.target.src.replace(
        //     "autoplay=0",
        //     "autoplay=1"
        //   );
        // }
      });
    };

    const observerOptions = {
      root: null, // Use the viewport as the root
      rootMargin: "0px",
      threshold: 0.5, // 50% visibility
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    videoRefs.current.forEach((iframe) => {
      observer.observe(iframe);
    });

    window.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
      observer.disconnect();
    };
  }, []);

  return (
    <Fragment>
      <div className='serv-body'>
        <div className='loader'>
          <div className='loader-inner'>
            <img
              src='assets/images/loader.gif'
              alt='Loader GIF'
              className='loader-img'
              loop='infinite'
            />
          </div>
        </div>
      </div>
      <Navigation />
      <div className='common-main-section serv-page-hero serv-bg' id='products'>
        <div className=''>
          <div className='common-page-hero-section'>
            <div className='common-page-hero-title'>Our Products</div>
            <div className='common-page-hero-subtitle'>
              Our products are built using cutting edge technologies and
              algorithms to solve real world problems
            </div>
          </div>
          {productsData.map((data, index) => (
            <div id={data.title}>
              <div className='item-heading-mobile'>
                <div className='item-logo'>
                  <a href='/'>
                    <img
                      src={data.logo}
                      alt='item-logo'
                      className='item-logo-image'
                    />
                  </a>
                </div>
                <div className='item-title mb-9'>{data.title}</div>
              </div>
              <div
                className='item-grid'
                style={{ paddingTop: "9rem" }}
                key={data.title}
                >
                <div
                  className={`${
                    data.imageOnLeft === false ? "image-order" : ""
                  }`}>
                  <iframe
                    ref={addToRefs}
                    className='item-image object-cover opacity-1'
                    src={`${data.video}?autoplay=0`}
                    title={data.title}
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowFullScreen></iframe>
                </div>
                <div className='item-text h-full text-left mx-8'>
                  <div className='item-heading'>
                    <div className='item-logo'>
                      <a href='/'>
                        <img
                          src={data.logo}
                          alt='item-logo'
                          className='item-logo-image'
                        />
                      </a>
                    </div>
                    <div className='item-title mb-9'>{data.title}</div>
                  </div>
                  <div className='mb-9 opacity-70'>
                    <ul>
                      {Object.values(data.point).map((point, index) => (
                        <li key={index} className='main-list'>
                          {point}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Link to={data.button.link} target='_blank'>
                    <ButtonIconBorder
                      name={data.button.name}
                      svgPathAttributes={{
                        fill: "currentColor",
                        d: "M4 11.25a.75.75 0 0 0 0 1.5h9.25V18a.75.75 0 0 0 1.28.53l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.28.53v5.25H4Z",
                      }}
                      styleClasses='justify-start'
                    />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <ContactUs />
      <Footer />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className='back-top' href='#'>
        <i className='fa fa-angle-up'></i>
      </a>
    </Fragment>
  );
};
export default Products;
