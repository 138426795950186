import "./App.css";
import Home from "./components/Home/Home";
import Navigation from "./components/Navigation/Navigation";
import Footer from "./components/Footer/Footer";
import Services from "./components/Services/Services";
import Products from "./components/Products/Products";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Careers from "./components/Careers/Careers";
// import Casestudy from "./components/Casestudy/Casestudy";
import DemoApp from "./components/Demoapp/DemoApp";
import EmergingTech from "./components/EmergingTech/EmergingTech";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Terms from "./components/termsconditon/terms";
import PrivacyPolicy from "./components/privacypolicy/Privcypolicy";
import { useState } from "react";
import SingleService from "./components/SingleService";
import Resource from "./components/Resources/Resources";
import SingleCaseStudy from "./components/SingleCaseStudy";
// import { useEffect } from 'react/cjs/react.development';

function App() {
  const [filter, setFilter] = useState("");
  const [filterdata, setFilterData] = useState("");
  const [filterservices, setFilterServices] = useState("");
  const filterFn = (filters) => {
    setFilter(filters);
  };
  const filterFnData = (filterdata) => {
    setFilterData(filterdata);
  };
  const filterFnServ = (filterservices) => {
    setFilterServices(filterservices);
  };
  // useEffect(() => {
  //   filterFn(filter)
  //   filterFnData(filterdata)
  //   filterFnServ(filterservices)
  // }, [filter, filterdata, filterservices])
  const App = () => {
    let route = useRoutes([
      { path: "/", element: <Home /> },
      { path: "/navigation", element: <Navigation /> },
      { path: "/services", element: <Services filterFnServ={filterFnServ} /> },
      { path: "/footer", element: <Footer /> },
      { path: "/products", element: <Products filterFn={filterFn} /> },
      { path: "/about", element: <About /> },
      { path: "/resources", element: <Resource /> },
      { path: "/contact", element: <Contact /> },
      { path: "/careers", element: <Careers /> },
      // { path: "/casestudy", element: <Casestudy filter={filter} /> },
      {
        path: "/demoapp",
        element: (
          <DemoApp
            filter={filter}
            filterdata={filterdata}
            filterservices={filterservices}
          />
        ),
      },
      {
        path: "/emergingtech",
        element: (
          <EmergingTech
            filterFnData={filterFnData}
            filterFnServ={filterFnServ}
          />
        ),
      },
      { path: "/terms&conditions", element: <Terms /> },
      { path: "/privacypolicy", element: <PrivacyPolicy /> },
      {
        path: "/ai-ml-solutions",
        element: (
          <SingleService
            metaTitle='Custom AI/ML Solutions for Strategic Business Outcomes'
            metaDescription='Discover our bespoke AI/ML solutions tailored to achieve your strategic business goals. We specialize in predictive analytics, AI-based process automation, and more. Learn how our solutions can transform your business operations.'
            title='Custom AI/ML Solutions for Strategic Business Outcomes'
            description='We collaborate with clients to create bespoke AI solutions aimed at achieving strategic business outcomes. 
            Our expertise spans across various industries, addressing complex problems with AI algorithms tailored for both structured and unstructured data. 
            We excel in classical Machine Learning and Deep Learning algorithm development.'
            // coreDetails={[
            //   {
            //     coreTitle: "Predictive Analytics",
            //     coreDescription:
            //       "We develop custom AI solutions for predictive analytics such as Churn Prediction and Recommendation Systems,Predictive maintenance/ Forecasting for Manufacturing ,Smart city analytics (Crowd prediction, Surveillance).",
            //   },
            //   {
            //     coreTitle: "AI-based process automation",
            //     coreDescription:
            //       " We develop custom text and image/video analytics based solutions such as Document parsing, semantic analysis , Warranty/ Claims analytics ,Visual inspection/ defect detection in Manufacturing Car damage detection, Smart KYC, Signature detection.",
            //   },
            // ]}
            image='assets/images/Services-1.jpeg'
            flowchartDetails={[
              {
                flowchartImage: "assets/images/flowchart-5.png",
                flowchartTitle: "Core Offerings - Predictive Analytics",
                flowchartSubtitle:
                  "We develop bespoke AI solutions for Predictive Analytics, including Churn Prediction, Recommendation Systems, Predictive Maintenance, Forecasting for Manufacturing, and Smart City Analytics (Crowd Prediction, Surveillance).",
              },
              {
                flowchartImage: "assets/images/flowchart-6.png",
                flowchartTitle: "Core Offerings - AI-based Process Automation",
                flowchartSubtitle:
                  "We create bespoke text and Image/Video analytics solutions, including Document Parsing, Semantic Analysis, Warranty/Claims Analytics, Visual Inspection/Defect Detection in Manufacturing, Car Damage Detection, Smart KYC, and Signature Detection.",
              },
            ]}
            valueDetails={[
              {
                value:
                  "Our analytics solution enhances crowd management and facility utilization for a global tourist destination",
              },
              {
                value:
                  "Reduced customer churn for an auto insurance company by up to 16% using our recommendation engine. ",
              },
              {
                value:
                  "Achieved a 5.7X projected gain in basket revenue for a travel portal using our AI engine.",
              },
              {
                value:
                  "Reduced overall insurance claim processing time by 20% with AI-based smart inspection for car damage detection.",
              },
            ]}
          />
        ),
      },
      {
        path: "/cloud-deployment",
        element: (
          <SingleService
            metaTitle='Comprehensive Cloud AI/ML Deployment Services'
            metaDescription='Explore our comprehensive suite of AI/ML cloud deployment services, including DevOps, MLOps, FinOps, and SecOps, designed to optimize your business operations and ensure robust security. Learn more about our expertise.'
            title='Comprehensive Cloud AI/ML Deployment Services'
            description='We offer a comprehensive suite of cloud services that seamlessly integrate into diverse business environments. Our AI/ML cloud deployment services ensure agile development, cost efficiency, and top-tier security.'
            image='assets/images/Services-2.jpeg'
            flowchartDetails={[
              {
                flowchartImage: "assets/images/flowchart-1.png",

                flowchartTitle: "Core Offerings - DevOps",

                flowchartSubtitle:
                  "Expertise in the implementation of DevOps practices, delivering end-to-end, scalable, and automated solutions that prioritize efficient development, continuous integration, and seamless deployment pipelines.",
              },
              {
                flowchartImage: "assets/images/flowchart-2.png",

                flowchartTitle: "Core Offerings - MLOps",
                  
                flowchartSubtitle:
                  "Expertise in MLOps to deploy, maintain, and track AI/ML models' performance reliably and efficiently. We also automate model retraining as required, ensuring continuous improvement.",
              },
              {
                flowchartImage: "assets/images/flowchart-3.png",

                flowchartTitle: "Core Offerings - FinOps",

                flowchartSubtitle:
                  "Expertise in billing optimizations and cost reductions for cloud deployments. Our FinOps solutions ensure that clients maximize their AI investments while minimizing expenses.",
              },
              {
                flowchartImage: "assets/images/flowchart-4.png",

                flowchartTitle: "Core Offerings - SecOps",

                flowchartSubtitle:
                  "Expertise in integrating comprehensive security into operations. Our proactive threat mitigation and incident response strategies efficiently protect infrastructure and data, ensuring compliance with industry regulations.",
              },
            ]}
            // coreDetails={[
            //   {
            //     coreTitle: "DevOps",
            //     coreDescription:
            //       "Expertise in the implementation of DevOps practices, delivering end-to-end, scalable, and automated solutions that prioritize efficient development, continuous integration, and seamless deployment pipelines.",
            //   },
            //   {
            //     coreTitle: "MLOps",
            //     coreDescription:
            //       "Expertise in MLOps that helps us deploy, maintain and track performance of AI/ML Models in Production Reliably and Efficiently. Also, automatically retrain models when required",
            //   },
            //   {
            //     coreTitle: "FinOps",
            //     coreDescription:
            //       "Expertise in Billing Optimizations and Cost Reductions in Cloud Deployments",
            //   },
            //   {
            //     coreTitle: "SecOps",
            //     coreDescription:
            //       "Expertise in ensuring integration of comprehensive security into operations. Our proactive threat mitigation and incident response strategies help protect infrastructure and data efficiently",
            //   },
            // ]}
            valueDetails={[
              {
                value:
                  "Optimized cloud services cost for our SaaS platform (AlgoFabric) by over 34% within 3 months.",
              },
              {
                value:
                  "Delivered end-to-end AI implementation on cloud for “Lead Analytics”  for a large US financial services client.",
              },
              {
                value:
                  "Executed end-to-end AI implementation on cloud for a smart city project for a foreign government.",
              },
              {
                value:
                  "Developed and deployed homegrown SaaS platform (AlgoFabric) on cloud.",
              },
            ]}
          />
        ),
      },
      {
        path: "/generative-ai",
        element: (
          <SingleService
            metaTitle='Innovative Generative AI Solutions for Business Transformation'
            metaDescription='Discover our expertise in Generative AI and LLM frameworks like OpenAI, Llama, and LangChain. Explore our solutions for AI-based process automation, chatbot development, and consulting services. Transform your business with GenAI.'
            title='Innovative Generative AI Solutions for Business Transformation'
            description='We specialize in working with LLM frameworks like OpenAI, Llama, and LangChain. 
            Our technology accelerators, built using the latest LLM models, enable rapid deployment of GenAI solutions for various businesses. 
            We also offer cloud-based AI services through Amazon BedRock and Azure OpenAI. Our ongoing research on multi-modal GenAI models ensures we stay at the forefront of this cutting-edge technology.'
            image='assets/images/Services-3.jpeg'
            iframeSrcLink='https://www.youtube.com/embed/PnSIKurY9cs'
            flowchartDetails={[
              {
                flowchartImage: "assets/images/flowchart-7.png",
                flowchartTitle:
                  "Core Offerings - Solution Development and Deployment",
                flowchartSubtitle:
                  "We specialize in customized Gen AI solutions to automate complex operations and boost corporate productivity using the latest innovations in the Gen AI space. Our innovative, intelligent, creative, and adaptable generative AI systems provide a transformative approach to solving complex challenges.",
                flowchartStyle: "60%",
              },
              {
                flowchartImage: "assets/images/flowchart-8.png",
                flowchartTitle:
                  "Core Offerings - LLM-Based Chatbot Developments",
                flowchartSubtitle:
                  "Expertise in developing LLM-based Document Q&A chatbots for various domains, including travel, healthcare, and finance. Our chatbots integrate with knowledge management systems to access multiple documents and repositories.",
                flowchartStyle: "60%",
              },
              {
                flowchartImage: "assets/images/flowchart-9.png",
                flowchartTitle:
                  "Core Offerings - Gen AI & LLM Consulting and Solution Architectures",
                flowchartSubtitle:
                  "Expertise in Gen AI strategy and consulting, including use case recognition and feasibility evaluation, technology assessment and choice, technical architecting, and AI deployment tailored to your business's specific needs, goals, and challenges.",
                flowchartStyle: "60%",
              },
            ]}
            valueDetails={[
              {
                value:
                  "Developed process automation solutions using LLM for a large Japanese company.",
              },
              {
                value:
                  "Created LLM-based chatbots and a customized document retrieval system.",
              },
              {
                value:
                  "Built a Digital Twin of a Human, based on LLM, for a Japanese technology company.",
              },
              {
                value:
                  "Offered several LLM-based tech accelerators on our Onestop.ai portal.",
              },
            ]}
            techStack={[
              {
                techImage: "assets/images/tech-stack-1.png",
              },
              {
                techImage: "assets/images/tech-stack-2.png",
              },
              {
                techImage: "assets/images/tech-stack-3.png",
              },
              {
                text: "Many More..",
              },
            ]}
          />
        ),
      },
      {
        path: "/caseStudy1",
        element: (
          <SingleCaseStudy
            title='Predictive Analytics in Financial Services'
            industry='Banking & Financial Services'
            technology='AI/ML Solutions'
            dataType='Text'
            description='Successfully provided customized Predictive Analytics solutions to a number of international clients in financial services.'
            image='assets/images/case-img1.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/Predictive+Analytics.pdf'
          />
        ),
      },
      {
        path: "/caseStudy2",
        element: (
          <SingleCaseStudy
            title='Our Work on Recommender Systems'
            industry='Retail'
            technology='AI/ML Solutions'
            dataType='Image'
            description='Used combination of multiple modeling techniques (matrix factorization and classification learning model) to identify best suited solution to recommend products from the product catalog based on the image of the original product.'
            image='assets/images/case-img2.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/Our+work+on+Recommender+Systems.pdf'
          />
        ),
      },
      {
        path: "/caseStudy3",
        element: (
          <SingleCaseStudy
            title='Our Work in Legal Analytics'
            industry='Legal'
            technology='Deep Learning / AI'
            dataType='text'
            //description for legal analytics
            description='Automated/semi-automated manual labour to read and extract meaningful information like name, place, location, entity, dates, amounts etc. from legal contracts using Natural Language Processing (NLP).'
            image='assets/images/case-img3.png'
            link=' https://algo-case-studies.s3.ap-south-1.amazonaws.com/Legal+Analytics.pdf'
          />
        ),
      },
      {
        path: "/caseStudy4",
        element: (
          <SingleCaseStudy
            title='Automated Quality Control to Detect Defects in Painted Parts'
            industry='Manufacturing'
            technology='Deep Learning / AI'
            dataType='Image'
            description='Successfully developed a computer vision model that increased accuracy in identifying defects in painted parts, resulting in a significant improvement in quality control for our client.'
            image='assets/images/case-img4.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/DefectDetection.pdf'
          />
        ),
      },
      {
        path: "/caseStudy5",
        element: (
          <SingleCaseStudy
            title='Our AI solutions for E-Commerce'
            industry='Retail'
            technology='AI/ML Solutions'
            dataType='Image'
            description='Take a look at our work in E-commerce Fashion AI where our innovative AI solutions helped our global retail clients enhance customer experiences.'
            image='assets/images/case-img5.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/E-commerce+solution+for+Retail.pdf'
          />
        ),
      },
      {
        path: "/caseStudy6",
        element: (
          <SingleCaseStudy
            title='Cooking up ML Recipes for a No/Low Code AI Platform'
            industry='Others'
            technology='AI/ML Solutions'
            dataType='Image'
            description='Collaborated with the client to deliver end-to-end deployment of Machine Learning recipes for multiple use cases.'
            image='assets/images/case-img6.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/Cooking+up+ML+recipes+for+a+NoLow+code+AI+platform.pdf'
          />
        ),
      },
      {
        path: "/caseStudy7",
        element: (
          <SingleCaseStudy
            title='Building an Automated Content Recommendation System'
            industry='Manufacturing'
            technology='AI/ML Solutions'
            dataType='Image'
            description='Built a Hybrid content recommendation system for an innovative leadership development platform focusing on vertical development. Used a combination of collaborative and content based approach using Deep learning and ML techniques.'
            image='assets/images/case-img7.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/RecommendationSystem.pdf'
          />
        ),
      },
      {
        path: "/caseStudy8",
        element: (
          <SingleCaseStudy
            title='Revolutionizing Industries with Digital Twin Technology'
            industry='Manufacturing'
            technology='AI/ML Solutions'
            dataType='Image'
            description='Dive into our case study on how our innovative approach to digital twins is revolutionizing industries. Discover how we are reshaping reality and maximizing efficiency one virtual replica at a time.'
            image='assets/images/case-img8.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/Digital+Twin+Case+study.pdf'
          />
        ),
      },
    ]);
    return route;
  };

  return (
    <div className='App'>
      <Router>
        <App />
      </Router>
    </div>
  );
}

export default App;

// npm run build
// surge ./build --domain algoanalyticsweb.surge.sh
